import Glide from '@glidejs/glide';

export function initDefaultGlide() {
    if(!document.querySelector('[data-glide-carousel]')) {
        return false;
    }

    const glide = new Glide('[data-glide-carousel]', {
        type: 'slider',
        autoplay: 10000,
        startAt: 0,
        perView: 1,
        gap: 0
    });
    glide.mount();

    return glide;
}