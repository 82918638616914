import $ from 'jquery';
import Sortable from 'sortablejs';

export function activateSortable() {
	// console.log('first test');
	var sortables = document.querySelectorAll('[data-sort]');
	for(var i = 0, length = sortables.length; i < length; i++) {
	    var sortableEl = sortables[i];
	    var model = sortableEl.getAttribute('data-sort');

	    var form = document.getElementById(sortableEl.getAttribute('data-sort-form'));
	    $(form).addClass('sort-form');
	    var sortable = Sortable.create(sortableEl, {
		handle: ".sort-handle",
		onStart: function() {
		},
		onSort: function() {
		    console.log('test');
		    if(form) {
			$(form).addClass('loading');
			sortable.option('disabled', true);

			var data = $(form).serialize();

			console.log(data);

			console.log('/' + model + '/sort');

		       $.post('/' + model + '/sort', data, function() {
			  $(form).removeClass('loading');
			  sortable.option("disabled", false);
			});
		    }
		}
	    });
	 }
    }