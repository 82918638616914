export function setupModalToggles(modalId) {
    let modal = document.getElementById(modalId);

    if(!modal) {
        return;
    }

    document.body.addEventListener('click', (e) => {
        if(e.target.dataset.openModal && e.target.dataset.openModal !== `${modalId}`) {
            return;
        } 

        if(e.target.dataset.closeModal && e.target.dataset.closeModal !== `${modalId}`) {
            return;
        } 

        if(e.target.dataset.toggleModal && e.target.dataset.toggleModal !== `${modalId}`) {
            return;
        } 

        if(e.target.dataset.openModal) {
            e.preventDefault();
            openModal(modal);
        }

        if(e.target.dataset.closeModal) {
            e.preventDefault();
            closeModal(modal);
        }

        if(e.target.dataset.toggleModal) {
            e.preventDefault();
            toggleModal(modal);
        }
    });
}

export function toggleModal(modal) {
    modal?.classList.toggle('is-open');
}

export function openModal(modal) {
    modal?.classList.add('is-open');
}

export function closeModal(modal) {
    modal?.classList.remove('is-open');
}

export function toggleModalById(modalId) {
    toggleModal(document.getElementById(modalId));
}

export function openModalById(modalId) {
    openModal(document.getElementById(modalId));
}

export function closeModalById(modalId) {
    closeModal(document.getElementById(modalId));
}
