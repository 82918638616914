import Vue from '../../../node_modules/vue/dist/vue.common.js';
import axios from 'axios';

export function initUserGroupApp(selector = '#user-group-app') {
    if(!document.querySelector(selector)) {
        return;
    }
    
    const urlParams = new URLSearchParams(window.location.search);
    const group_id = urlParams.get('edit') || '';
    let vueData = new Vue({
        el: '#user-group-app',
        data: {
            name: '',
            group_name: '',
            user_id: null,
            picked_users: new Array(),
            user_name: null,
            users: null,
            results: null,
            msg: null,
            alreadySelected: false,
            hideSelections: false
        },

        mounted() {
            axios.get('/email-groups/user-list')
                .then(response => (this.users = response.data))
            if (group_id) {
                axios.get('/email-groups/get-current-list/' + group_id)
                    .then((response) => {
                        this.picked_users = response.data;
                        this.group_name = response.data[0].group_name;
                    });
            }
        },

        methods: {
            getUserById(id) {
                let match = null;
                this.users.forEach((user) => {
                    if(user.id === id) {
                        match = user;
                    }
                });
                
                return match;
            },
            getPickedUserById(id) {
                let match = null;
                this.picked_users.forEach((user) => {
                    if(user.id === id) {
                        match = user;
                    }
                });
                
                return match;
            },
            moveUser(id) {
                let pickedUser = this.getUserById(id);
                
                this.hideSelections = false;
                this.alreadySelected = this.getPickedUserById(id);
                if(this.alreadySelected) {
                    this.hideSelections = true;
                   return; 
                }
                
                this.picked_users.push(pickedUser);

                this.user_id = pickedUser.id;
                this.name = '';
            },
            removeUser(index) {
                this.picked_users.splice(index, 1);
            },
            saveGroup() {
                if (this.group_name) {
                    axios.post(`/email-groups/save-group/${group_id}`, {
                        group_name: this.group_name,
                        users: this.picked_users
                    }).then(function(response) {
                        window.location.href = '/settings#user-groups';
                    });
                } else {
                    this.msg = 'Group Name is required';
                }
            },
            searchNames() {
                this.hideSelections = false;
                let name = this.name.toLowerCase();
                console.log(name);
                this.results = new Array();
                this.results = this.users.filter(function(user) {
                    let search = user.first_name.concat(' ', user.last_name).toLowerCase();
                    if (user.first_name.toLowerCase().indexOf(name) != -1 && name.length > 2) {
                        return true;
                    }
                });
            }
        }
    });
}