import Vue from '../../../node_modules/vue/dist/vue.common.js';
import Tabset from '../components/tabset';
import axios from 'axios';

export function initDashboardPromosApp(selector = '#dashboard-promos') {
    if(!document.querySelector(selector)) {
        return;
    }
    
    const API_URL = '/api/v1/search/frontPage.json';
    
    const urlParams = new URLSearchParams(window.location.search);
    const group_id = urlParams.get('edit') || '';
    let vueData = new Vue({
        el: selector,
        data: {
            types: [
                'trending',
                'new',
                'evergreen'
            ],
            app_loaded: false,
            loading: {
                'evergreen': false,
                'new': false,
                'trending': false
            },
            promos: {
                'evergreen': {
                    current_page: 0,
                    result_count: 1,
                    total_pages: 1,
                    results: []
                },
                'new': {
                    current_page: 0,
                    result_count: 0,
                    total_pages: 1,
                    results: []
                },
                'trending': {
                    current_page: 0,
                    result_count: 0,
                    total_pages: 1,
                    results: []
                }
            },
            scroll_observer: null
        },

        mounted() {
            // init tabsets
            const tabsets = document.querySelectorAll('[data-vue-tabset]');
            tabsets.forEach((tabset) => {
                new Tabset(tabset);
                
                if(window.location.hash && document.querySelectorAll(`[data-toggle-tabset="${window.location.hash.replace('#', '')}"]`).length) {
                    window.location.hash = selector;
                }
            });
            
            this.getResults('new', true);
            this.getResults('evergreen', true);
            this.getResults('trending', true);
            
            this.setNavPosition(this.$refs.initialToggle);
            
            window.setTimeout(() => {
                this.app_loaded = true;
            }, 200);
        },

        methods: {
            setNavPosition(tabNavToggle) {
                this.$refs.tabsNav.style.setProperty("--tab-width", `${tabNavToggle.offsetWidth}px`);
                this.$refs.tabsNav.style.setProperty("--tab-offset", `${tabNavToggle.offsetLeft}px`);
            },
            
            onTabClick(e) {
              this.setNavPosition(e.target);  
            },
            
           setLoading(type, is_loading = true) {
               let loading = {};
               loading[type] = is_loading;
               this.loading = Object.assign({}, this.loading, loading);
           },
           
           getResults(type = 'new') {
                if(this.promos[type].current_page >= this.promos[type].total_pages || this.loading[type]) {
                    return;
                }
               
                this.setLoading(type);
                
                axios.get(`${API_URL}?promos=${type}&page=${this.promos[type].current_page + 1}`)
                .then(response => {
                    let promos = {};
                    let data = response.data.data;
                    
                    promos[type] = {
                        current_page: data.current_page,
                        result_count: data.result_count,
                        total_pages: data.total_pages
                    };
 
                    promos[type].results = this.promos[type].results.concat(data.results || []);
                    this.promos = Object.assign({}, this.promos, promos);
                    
                    this.setLoading(type, false);

                    window.setTimeout(() => {
                        this.setupScrollTracking();
                    }, 200);
                });
           },
           
           setupScrollTracking() {
               this.removeScrollTracking();
               
               const app_el = document.getElementById(selector.replace('#', ''));
               const targets = app_el.querySelectorAll('[data-inview-load]');

               let options = {
                    root: null,
                    rootMargin: '0px 0px -300px 0px',
                    threshold: 1
                }
                
                let setInview = (entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            let type = entry.target.dataset.inviewLoad;
                            this.getResults(type);
                        }
                    });
                }
                
                /* global IntersectionObserver */
                this.scroll_observer = new IntersectionObserver(setInview, options);
                
                
                targets.forEach(target => {
                    this.scroll_observer.observe(target);
                });
           },
           
           removeScrollTracking() {
               const app_el = document.getElementById(selector.replace('#', ''));
               const targets = app_el.querySelectorAll('[data-inview-load]');
               
               if(this.scroll_observer) {
                    targets.forEach(target => {
                        this.scroll_observer.unobserve(target);
                    });
                    
                    this.scroll_observer = null;
               }
           }
        }
    });
}