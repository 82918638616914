import * as $ from 'jquery';

$(document).ready(function() {
    $('body').on('click', '[data-toggle="promo-popup"]', function(e) {
        e.preventDefault();
        
        var trigger = $(this);
        removePopup();
        createPopup(trigger);
    });
    
    $('body').on('click', '[data-close="promo-popup"]', function(e) {
        e.preventDefault();
        removePopup();
    });
    
    function createPopup(trigger) {
        var popup;
        var preview = trigger.attr('data-preview');
        var preview2 = trigger.attr('data-preview2');
        var name = trigger.attr('data-name');
        var url = trigger.attr('href');
        var html = '<a href="#" data-close="promo-popup">&times;</a>';
        var doubleClass = preview2 === 'false' ? '' : 'double';

        if(preview2 === '') {
            if(url !== '#') {
                html +='<a href="' + url + '">' + '<img src="'+ preview + '"></a>';
            }
            else {
                html +='<img src="'+ preview + '">';
            }
        }
        else {
            preview2 = + preview2;
            html += '<div class="row">';
            html += '<div class="col-6">';
            html += '<h3>Page 1</h3>';
            
            if(url !== '#') {
                html += '<a href="' + url + '">' + '<img src="'+ preview + '"></a>';
            }
            else {
                html += '<img src="'+ preview + '">';
            }
            
            html += '</div>';
            html += '<div class="col-6">';
            html += '<h3>Page 2</h3>';
            
            if(url !== '#') {
                html += '<a href="' + url + '">' + '<img src="'+ preview2 + '"></a>';
            }
            else {
                html += '<img src="'+ preview2 + '">';
            }
            
            html += '</div>';
            html += '</div>';
        }
        
        if(url !== '#') {
            html += '<h2 class="text-center"><a href="' + url + '">' + name + '</a></h2>';
        }
        else {
            html += '<h2 class="text-center">' + name + '</h2>';;
        }
        popup = $('<div id="promo-popup" class="promo-popup ' + doubleClass + '">');
        
        popup.html(html);
        $('body').append(popup);
        
        window.setTimeout(function() {
            popup.addClass('active');
        }, 0);
    }
    
    function removePopup() {
        var popup = $('#promo-popup');
        
        if(popup.length) {
            popup.removeClass('active');
            
            window.setTimeout(function() {
                popup.remove();                
            }, 300)
        }
    }
});