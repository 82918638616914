import Vue from '../../../node_modules/vue/dist/vue.common.js';
import axios from 'axios';

export function initAdvancedSearchApp(selector = '#advanced-search') {
    if(!document.querySelector(selector)) {
        return;
    }

    const API_URL = '/api/v1/search.json';
    

    let vueData = new Vue({
        el: selector,
        data: {
            search: {},
            options: {
                'ad_groups': [],
                'ad_promos': [],
                'languages': [],
                'types': []
            },
            app_loaded: false,
            loading: false,
            promos: {
                current_page: 0,
                result_count: 1,
                total_pages: 1,
                results: []
            },
            scroll_observer: null
        },

        mounted() {
            this.getUrlSearchParams();
            this.getResults();
            this.app_loaded = true;
            this.setLoading();
        },

        methods: {
           setLoading(is_loading = true) {
               this.loading = is_loading;
           },

           getResults(reset_results = false, next_page = false) {
                if((this.promos.current_page >= this.promos.total_pages || this.loading) && !reset_results ) {
                    return;
                }

                let query = this.getSearchQuery();

                if(next_page && this.promos.current_page < this.promos.total_pages) {
                    query = query.length ? `${query}&page=${this.promos.current_page + 1}` : `?page=${this.promos.current_page + 1}`;
                }

                this.setLoading();


                //console.log('query: ' + query);


                axios.get(`${API_URL}${query}`)
                    .then(response => {
                        let promos = {};
                        let data = response.data.data;

                        //console.table(data);

                        promos = {
                            current_page: data.pagination?.current_page || 0,
                            result_count: data.pagination?.result_count || 0,
                            total_pages: data.pagination?.total_pages || 0
                        };

                        promos.results = reset_results ? data.results : this.promos.results.concat(data.results || []);
                        this.promos = Object.assign({}, promos);

                        this.setOptions(data.options, data.form?.values);

                        //console.log('options: ' + JSON.stringify(data.options));

                        this.setLoading(false);

                        window.setTimeout(() => {
                            this.setupScrollTracking();
                        }, 200);
                    });
           },

           getSearchQuery() {
               let query = [];

               Object.keys(this.search).forEach((term) => {
                   if(!this.search[term].length) {
                       return;
                   }

                   let values = this.search[term].map((item) => item.id).join(',');
                   let query_part = `"${term}":[${values}]`;

                   query.push(query_part);
               });

               if(query.length) {
                  return `?values={${query.join(',')}}`;
               }

               return '';
           },

           setOptions(response_options, form_values) {

               let options = {};

               ["ad_promos", "ad_groups", "languages", "types"].forEach((type_key) => {
                    
                    // make sure options[type_key] is an array
                    if(Array.isArray(response_options[type_key])) {
                        //if parsed as array 

                        options[type_key] = response_options[type_key];
                    }
                    else {
                        //Was parsed as object, need to convert to array

                        options[type_key] = [];

                        
                        Object.keys(response_options[type_key]).forEach((option_key) => {
                            options[type_key].push({
                               id: option_key,
                               name: response_options[type_key][option_key].label
                            });
                        });
                    }
                    
                    
                    // copy ad info from response_options to this.search
                    this.search[ type_key ] = options[ type_key ].filter( (opt) => {
                        return form_values[ type_key ]?.includes( parseInt(opt.id) )
                    });
               });

               this.options = Object.assign({}, options);
           },



           

           setupScrollTracking() {
               this.removeScrollTracking();

               const app_el = document.getElementById(selector.replace('#', ''));
               const targets = app_el.querySelectorAll('[data-inview-load]');

               let options = {
                    root: null,
                    rootMargin: '0px 0px -300px 0px',
                    threshold: 1
                }

                let setInview = (entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            this.getResults(false, true);
                        }
                    });
                }

                /* global IntersectionObserver */
                this.scroll_observer = new IntersectionObserver(setInview, options);


                targets.forEach(target => {
                    this.scroll_observer.observe(target);
                });
           },

           removeScrollTracking() {
               const app_el = document.getElementById(selector.replace('#', ''));
               const targets = app_el.querySelectorAll('[data-inview-load]');

               if(this.scroll_observer) {
                    targets.forEach(target => {
                        this.scroll_observer.unobserve(target);
                    });

                    this.scroll_observer = null;
               }
           },

           addSearchTerm(e, term) {
               let search = {};
               let item_id = e.target.value;
               let term_list = this.search[term]?.slice() || []
               e.target.value = '';

               let option = this.getOptionById(term, item_id);

              if(option && !this.searchHasOption(term, option.id)) {
 
                search[term] = term_list;
                search[term].push(option);

                this.search = Object.assign({}, this.search, search);

                this.getResults(true);
              }

           },

           clearSearchItem(term, item_id) {

               let search = {};

               search[term] = this.search[term].filter((item) => {
                   return item.id !== item_id;
               });

               this.search = Object.assign({}, this.search, search);

               
               this.getResults(true);
           },

           searchHasOption(term, option_id) {
               let has_option = false;

               if(this.search[term]) {
                   this.search[term].forEach((option) => {
                       if(option.id === option_id || option.id === parseInt(option_id)) {
                           has_option = true;
                       }
                   });
               }

               return has_option;
           },

           clearSearch(term = null) {
               let search = {};

               if(term) {
                   search = this.search;
                   search[term] = [];
               }

               this.search = Object.assign({}, search);

               this.getResults(true);
            },

            getOptionById(term, id) {
                let matched_option = null;

                this.options[term].forEach((option) => {
                    if(!matched_option && (option.id === id || parseInt(option.id) === parseInt(id))) {
                        matched_option = Object.assign({}, option);

                    }
                });

                return matched_option;
            },
            
            getUrlSearchParams() {
                const urlParams = new URLSearchParams(window.location.search);
                const ad_group = urlParams.get('ad_group') || false;

                const ad_promo = urlParams.get('ad_promo') || false;
                const language = urlParams.get('language') || false;

                const type = urlParams.get('type') || false;


                if(ad_group) {
              
                    this.options.ad_groups = [{id: ad_group}];
                    this.search = {...this.search, ad_groups: [this.getOptionById('ad_groups', ad_group)]};
                }
                
                if(ad_promo) {
                   
                    this.options.ad_promos = [{id: ad_promo}];
                    this.search = {...this.search, ad_promos: [this.getOptionById('ad_promos', ad_promo)]};
                }
                
                if(language) {
                    this.options.languages = [{id: language}];
                    this.search = {...this.search, languages: [this.getOptionById('languages', language)]};
                }
                
                if(type) {
                    this.options.types = [{id: type}];
                    this.search = {...this.search, types: [this.getOptionById('types', type)]};
                }

            
            }
        }
    });
}
