import getHiddenHeight from 'get-hidden-height';

export function initExpandsets(selector = '[data-expandset]') {
    const expandsets = document.querySelectorAll(selector);

    expandsets.forEach((expandset) => {
        setupExpandsetToggles(expandset, expandset?.dataset?.expandsetAccordion);
    });
}

export function setupExpandsetToggles(expandsetId, isAccordion = false) {
    let expandset = typeof expandsetId === 'string' ? document.getElementById(expandsetId) : expandsetId;

    expandset.classList.add('expandset');

    if(!expandset) {
        return;
    }

    isAccordion = expandset.dataset.expandsetAccordion || isAccordion;

    let expandset_toggles = expandset.querySelectorAll(`[data-toggle-expandset]`);

    expandset_toggles.forEach((expandset_toggle) => {
        const panelId = expandset_toggle.dataset.toggleExpandset;

        expandset_toggle.addEventListener('click', e => {
            e.preventDefault();

            toggleExpandsetPanel(expandset, panelId);

            if(isAccordion) {
                closeAllExpandsetPanels(expandset, panelId);
            }
        });
    });
}

export function toggleExpandsetPanel(expandset, panelId) {
    const panel = expandset.querySelector(`[data-expandset-panel="${panelId}"]`);

    if(!panel || !expandset) {
        return;
    }

    const toggles = expandset.querySelectorAll(`[data-toggle-expandset="${panelId}"]`);
    toggles.forEach((toggle) => {
        toggle.classList.toggle('is-open');
    });
    
    panel.classList.add('transition');
    panel.classList.toggle('is-open');
    
    window.setTimeout(() => {
        panel.style.maxHeight = `${panel.classList.contains('is-open') ? getHiddenHeight(panel) : '0'}px`;
    }, 0);

    window.setTimeout(() => {
        panel.classList.remove('transition');
    }, 300);
}

export function closeAllExpandsetPanels(expandset, ommitPanelId) {
    let expandset_toggles = expandset?.querySelectorAll(`[data-toggle-expandset]`);
    let expandset_panels = expandset?.querySelectorAll(`[data-expandset-panel]`);

    expandset_toggles.forEach((toggle) => {
        if(toggle.dataset.toggleExpandset !== ommitPanelId) {
            toggle.classList.remove('is-open');
        }
    });

    expandset_panels.forEach((panel) => {
        if(panel.dataset.expandsetPanel !== ommitPanelId) {
            panel.classList.remove('is-open');
            panel?.classList.add('transition');
            panel.style.maxHeight = '0';
            window.setTimeout(() => {
                panel?.classList.remove('transition');
            }, 300);
        }
    });
}
