function setNavPosition(tabNav, tabNavToggle) {
    tabNav.style.setProperty("--tab-width", `${tabNavToggle.offsetWidth}px`);
    tabNav.style.setProperty("--tab-offset", `${tabNavToggle.offsetLeft}px`);
}

export default function initTabsNavs(){
    const tabNavs = document.querySelectorAll('.tabs-nav');
    
    tabNavs.forEach( tabNav => {
        const activeToggle = tabNav.querySelector('.tabs-toggle.open');
        if(activeToggle) {
            setNavPosition(tabNav, activeToggle);
        }
        
        window.setTimeout(() => {
            tabNav.classList.add('loaded');
        }, 200);
        
        const tabNavToggles = tabNav.querySelectorAll('.tabs-toggle');
        
        tabNavToggles.forEach( tabNavToggle => {
            tabNavToggle.addEventListener('click', () => {
                setNavPosition(tabNav, tabNavToggle);
                
            })
        })
    })
}