import $ from 'jquery';

// $(document).ready(function() {
//     $('#toggleFavorite').on('click', function(e) {
//         e.preventDefault();
//         var favorited = $(this).hasClass('favorited');
//         var heartImg = $(this).children('img');
//         var type = $(this).attr('data-type');
//         var typeId = $(this).attr('data-id');
//         var fadeOutSpeed = 6000;

//         // console.log('type is ' + type);
//         // console.log('type_id is ' + typeId);

//         if (favorited) {
//             $(this).removeClass('favorited'); // for swapping with css

//             // ajax call to un-favorite
//             $.post('/my-favorites/remove', { type: type, type_id: typeId}, function(data) {
//                 data = JSON.parse(data);
//                 console.log(data.message);
//                 if (data.error == 1) {
//                     $('#favoriteSuccessMsg').hide();
//                     $('#favoriteErrorMsg').html(data.message).show().fadeOut( fadeOutSpeed );
//                 } else {
//                     heartImg.attr('src', '/img/outlined-heart.png');
//                     $('#favoriteErrorMsg').hide();
//                     $('#favoriteSuccessMsg').html(data.message).show().fadeOut( fadeOutSpeed );
//                 }
//             });
//         } else {
//             $(this).addClass('favorited'); // for swapping with css

//             // ajax call to favorite
//             $.post('/my-favorites/add', { type: type, type_id: typeId}, function(data) {
//                 data = JSON.parse(data);
//                 console.log(data.message);
//                 if (data.error == 1) {
//                     $('#favoriteSuccessMsg').hide();
//                     $('#favoriteErrorMsg').html(data.message).show().fadeOut( fadeOutSpeed );
//                 } else {
//                     heartImg.attr('src', '/img/red-heart.png');
//                     $('#favoriteErrorMsg').hide();
//                     $('#favoriteSuccessMsg').html(data.message).show().fadeOut( fadeOutSpeed );
//                 }
//             });
//         }
//         // console.log('clicked');
//     });
// });
export function initFavoritesToggle() {
    if(document.getElementById('toggleFavorite')) {
        initHeart()
    }
}
function  initHeart() {

    function makeRequest(name, post_data) {
        return new Request(`/my-favorites/${name}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest', //hits cake $this->request->is('ajax')
            },
            body: JSON.stringify(post_data)});
    }

    let toggle_element = document.querySelector('#toggleFavorite');

    if(!toggle_element) {
        return;
    }

    toggle_element.addEventListener('click', (e) => {
        e.preventDefault();

        let favorited = toggle_element.classList.contains('favorited');
        let heartImg = toggle_element.children[0];
        let type = toggle_element.dataset.type;
        let type_id = toggle_element.dataset.id;
        let fadeOutSpeed = 4500;

        let post_data = {
            'type': type,
            'type_id': type_id
        }

        //remove
        if(favorited) {
            console.log('remove');
            toggle_element.classList.remove('favorited');

            const request = makeRequest('remove', post_data);

            fetch(request)
            .then(response => response.json())
            .then(data => {
                let error = document.querySelector('#favoriteErrorMsg');
                let success = document.querySelector('#favoriteSuccessMsg');
                if (data.error == 1) {
                    $('#favoriteSuccessMsg').hide();
                    $('#favoriteErrorMsg').html(data.message).show().fadeOut( fadeOutSpeed );
                } else {
                    heartImg.srcset = '/img/outlined-heart.png';
                    $('#favoriteErrorMsg').hide();
                    $('#favoriteSuccessMsg').html(data.message).show().fadeOut( fadeOutSpeed );
                }
            });

        // add
        }else{
            console.log('add');
            toggle_element.classList.add('favorited');


            const request = makeRequest('add', post_data);

            fetch(request)
            .then(response => response.json())
            .then(data => {
                let error = document.querySelector('#favoriteErrorMsg');
                let success = document.querySelector('#favoriteSuccessMsg');
                console.log(data.message);
                if (data.error == 1) {
                    $('#favoriteSuccessMsg').hide();
                    $('#favoriteErrorMsg').html(data.message).show().fadeOut( fadeOutSpeed );
                } else {
                    heartImg.srcset = '/img/red-heart.png';
                    $('#favoriteErrorMsg').hide();
                    $('#favoriteSuccessMsg').html(data.message).show().fadeOut( fadeOutSpeed );
                }
            });
        }
    });
};
